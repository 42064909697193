import axios from 'axios'

export default {
    get: () => axios.get('users'),
    getAdmins: () => axios.get('users/admins'),
    store: (data) => axios.post('users/store', data),
    update: (data) => axios.post('users/update', data),
    delete: (id) => axios.post('users/delete', id),
    getUserPermissions: () => axios.get('users/permissions'),
    getRoles: () => axios.get('roles'),
}